import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { removeAuthHeader, setAuthHeader } from '@api'
import { LOCAL_STORAGE_KEYS } from '@constants'
import { AuthResponse } from '@types'

const setInterceptors = (api: AxiosInstance): AxiosInstance => {
  api.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    async (error: AxiosError) => {
      console.debug(
        'hubo un error de request!',
        error,
        error.response?.data.message,
        error.response?.status,
        error.request.responseUrl,
      )
      if (error.response?.status === 401 || error.response?.data.message === '401 Unauthorized') {
        if (
          error.request.responseURL.indexOf('refresh-token') ===
          error.request.responseURL.indexOf('login')
        ) {
          try {
            const refreshToken = window.localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)!
            setAuthHeader(refreshToken)
            const { data } = await api.post<AuthResponse>('/auth/refresh-token')
            setAuthHeader(data.token)
            window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, data.token)
            window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, data.refreshToken)
            const lastRequest = error.config
            lastRequest.headers.Authorization = `Bearer ${data.token}`
            return api.request(lastRequest)
          } catch (refreshError) {
            console.error('Error refreshing token', refreshError)
            removeAuthHeader()
            window.localStorage.clear()
            window.location.href = '/login'
            return Promise.reject(error)
          }
        } else return Promise.reject(error)
      }
      return Promise.reject(error)
    },
  )
  return api
}

export { setInterceptors }
