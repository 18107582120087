import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useCallback, useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  TableFooter,
  TablePagination,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Link,
  Grid,
} from '@mui/material'

import { AuthorsApi } from '@api'
import { useDebounce } from '@hooks'
import { SearchBar, TablePaginationActions } from '@components'

import { Author, AuthorsResponse, PaginationParams, AuthorStatus } from '../../types'

function AuthorsList() {
  const [error, setError] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [query, setQuery] = useState('')
  const [status, setStatus] = useState('')
  const debouncedQuery = useDebounce(query, 200)
  const [authors, setAuthors] = useState<Author[]>([])

  const [pagination, updatePagination] = useState<PaginationParams>({ page: 0, rowsPerPage: 25 })

  const fetchAuthors = useCallback(async () => {
    return AuthorsApi.getAuthors({ name: debouncedQuery, status }, pagination)
      .then((response: AuthorsResponse) => {
        setAuthors(response.authors)
        setIsLoaded(true)
      })
      .catch((e: Error) => {
        console.log(e)
        setError(e.message)
      })
  }, [pagination, debouncedQuery, status])

  const handleChangePage = useCallback(
    (event, page) => {
      updatePagination({ ...pagination, page })
    },
    [pagination],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      updatePagination({ page: 0, rowsPerPage: parseInt(event.target.value, 10) })
    },
    [],
  )

  useEffect(() => {
    fetchAuthors()
  }, [fetchAuthors])

  const getImage = (url?: string) => {
    if (url) {
      return <img src={`${url}`} alt="not found" />
    }
    return null
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4">Authors</Typography>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={8}>
          <SearchBar
            setSearchQuery={(q: string) => {
              setQuery(q)
              updatePagination({ ...pagination, page: 0 })
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ minWidth: 150 }}>
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              labelId="status-select-label"
              value={status}
              label="Status"
              onChange={e => setStatus(e.target.value)}
            >
              {Object.keys(AuthorStatus).map((as: string) => (
                <MenuItem value={as}>{as}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={() => fetchAuthors()}>Refresh</Button>
        </Grid>
      </Grid>

      {isLoaded ? (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell component="th" width="20%" align="left">
                  ID
                </TableCell>
                <TableCell component="th" width="20%" align="left">
                  Name
                </TableCell>
                <TableCell component="th" width="20%" align="center">
                  Image
                </TableCell>
                <TableCell component="th" width="20%" align="center">
                  Status
                </TableCell>
                <TableCell component="th" width="20%" align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {authors.length > 0 &&
                authors.map(row => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="center">{getImage(row?.image_url)}</TableCell>
                    <TableCell align="center">{row?.status}</TableCell>
                    <TableCell align="center">
                      <Link href={`/authors/${row.id}`} color="inherit" target="_blank">
                        View
                      </Link>
                      <br />
                      <Link href={`/authors/${row.id}/edit`} color="inherit" target="_blank">
                        Edit
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  colSpan={3}
                  count={-1}
                  rowsPerPage={pagination.rowsPerPage}
                  page={pagination.page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
          {error}
        </Box>
      )}
    </Container>
  )
}

export { AuthorsList }
