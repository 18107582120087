import { useParams } from 'react-router-dom'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import {
  Container,
  Grid,
  Button,
  TextField,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  CircularProgress,
} from '@mui/material'

import { Author, AuthorStatus } from '@types'

import { AuthorsApi } from '../../api'

const AuthorEdit = () => {
  const { id } = useParams<{ id: string }>()
  const [author, setAuthor] = useState<Author | undefined>()
  const [imageToUpload, setImageToUpload] = useState<File | null>(null)
  const [itemImage, setItemImage] = useState<string | undefined>()
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const fetchAuthor = async (authorId: string) => {
    const { author } = await AuthorsApi.getAuthor(authorId)
    setAuthor(author)
    setItemImage(author.image_url)
  }

  useEffect(() => {
    fetchAuthor(id)
  }, [id])

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const imageFile = e.target.files[0]
    if (imageFile.size > 3_000_000) {
      setImageToUpload(null)
      setItemImage(undefined)
    } else {
      setImageToUpload(imageFile)
      setItemImage(URL.createObjectURL(imageFile))
    }
  }

  const removeImage = useCallback(() => {
    if (imageToUpload) {
      setImageToUpload(null)
      setItemImage(undefined)
    }
  }, [imageToUpload])

  const onSubmit = useCallback(
    async (data: Author | undefined) => {
      setLoading(true)
      if (data) {
        try {
          await AuthorsApi.updateAuthor(data, imageToUpload)
          setError('')
        } catch (e) {
          setError('Algo salio mal :(')
        }
      }
      setLoading(false)
    },
    [imageToUpload],
  )
  return (
    <>
      <Container maxWidth="sm">
        <Box
          component="form"
          noValidate
          onSubmit={e => {
            e.preventDefault()
            onSubmit(author)
          }}
          sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <Avatar src={itemImage} sx={{ width: 100, height: 100, mb: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Button color="primary" variant="contained" component="label" sx={{ height: 48 }}>
                    Choose Image
                    <input
                      hidden
                      accept="image/png, image/gif, image/jpeg"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disabled={!imageToUpload}
                    color="primary"
                    variant="contained"
                    component="label"
                    sx={{ height: 48, ml: 2 }}
                    onClick={() => removeImage()}
                  >
                    Remove Image
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="name"
                name="name"
                required
                fullWidth
                id="name"
                label="Full Name"
                autoFocus
                value={author?.name ?? ''}
                onChange={e => setAuthor({ ...author, name: e.target.value } as Author)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="description"
                label="Description"
                name="description"
                type="text"
                value={author?.description ?? ''}
                onChange={e => setAuthor({ ...author, description: e.target.value } as Author)}
              />
            </Grid>
            {author && (
              <Grid item xs={12}>
                <InputLabel id="status-select-label">Status</InputLabel>

                <Select
                  labelId="status-select-label"
                  value={author?.status}
                  label="Status"
                  onChange={e => setAuthor({ ...author, status: e.target.value } as Author)}
                >
                  {Object.keys(AuthorStatus).map((as: string) => (
                    <MenuItem value={as} key={as}>
                      {as}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
          </Grid>
          {!isLoading ? (
            <>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Save
              </Button>
              {error}
            </>
          ) : (
            <CircularProgress />
          )}
        </Box>
      </Container>
    </>
  )
}

export { AuthorEdit }
