import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useCallback, useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  TableFooter,
  TablePagination,
  Link,
} from '@mui/material'

import { UsersApi } from '@api'
import { useDebounce } from '@hooks'
import { TablePaginationActions } from '@components'

import { User, PaginationParams, UsersResponse } from '../../types'

function UsersList() {
  const [error, setError] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [query] = useState('')
  const debouncedQuery = useDebounce(query, 200)
  const [items, setItems] = useState<User[]>([])

  const [pagination, updatePagination] = useState<PaginationParams>({ page: 0, rowsPerPage: 25 })

  const fetchItems = useCallback(async () => {
    return UsersApi.getUsers(pagination)
      .then((response: UsersResponse) => {
        setItems(response.users)
        setIsLoaded(true)
      })
      .catch((e: Error) => {
        console.log(e)
        setError(e.message)
      })
  }, [pagination, debouncedQuery])

  const handleChangePage = useCallback(
    (event, page) => {
      updatePagination({ ...pagination, page })
    },
    [pagination],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      updatePagination({ page: 0, rowsPerPage: parseInt(event.target.value, 10) })
    },
    [],
  )

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  return (
    <Container maxWidth="lg">
      <Typography variant="h4">Users</Typography>
      {isLoaded ? (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell component="th" width="20%" align="left">
                  ID
                </TableCell>
                <TableCell component="th" width="20%" align="left">
                  Email
                </TableCell>
                <TableCell component="th" width="20%" align="center">
                  Role
                </TableCell>
                <TableCell component="th" width="20%" align="center">
                  Created
                </TableCell>
                <TableCell component="th" width="20%" align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.length > 0 &&
                items.map(row => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="center">{row?.role}</TableCell>
                    <TableCell align="center">{row?.created_at.toString()}</TableCell>
                    <TableCell align="center">
                      <br />
                      <Link href={`/users/${row.id}/edit`} color="inherit" target="_blank">
                        Edit
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  colSpan={3}
                  count={-1}
                  rowsPerPage={pagination.rowsPerPage}
                  page={pagination.page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
          {error}
        </Box>
      )}
    </Container>
  )
}

export { UsersList }
