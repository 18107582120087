import { AuthResponse } from '@types'

import { api } from './axios'

interface Payload {
  email: string
  password: string
}

const login = async (payload: Payload) => {
  const body = { ...payload }
  const response = await api.post<AuthResponse>('/auth/admin/login', body)

  return response.data
}

export const AuthApi = {
  login,
}
