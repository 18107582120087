import { Container } from '@mui/material'
import React, { ReactNode } from 'react'

import { NavigationBar } from '../NavigationBar'

interface GeneralLayoutProps {
  body: ReactNode
  bodyStyle?: React.CSSProperties
}

const GeneralLayout = ({ body, bodyStyle }: GeneralLayoutProps) => {
  return (
    <div>
      <NavigationBar />
      <br />
      <Container sx={bodyStyle}>{body}</Container>
    </div>
  )
}

export { GeneralLayout }
