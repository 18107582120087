import { useForm } from 'react-hook-form'
import { useHistory, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { ErrorResponse } from '@types'
import { useUser } from '@contexts'

import { useLogin } from './hooks'

const defaultValues = {
  password: '',
  email: '',
}

const theme = createTheme()

const Login = () => {
  const { state: user } = useUser()
  const history = useHistory()
  const { t } = useTranslation()

  const { register, handleSubmit } = useForm({ defaultValues })

  const onSubmit = data => {
    login(data)
  }

  const { login } = useLogin({
    onError: (error: ErrorResponse) => {
      if (error.type === 'LOGIN_ATTEMPTS_EXCEEDED') {
        history.push('/login/recover', { reason: t('login.account_locked') })
      }
    },
  })

  return user ? (
    <Redirect to="/" />
  ) : (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('login.login')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <TextField
              {...register('email')}
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('login.email_label')}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              {...register('password')}
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('login.password_label')}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {t('login.login')}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export { Login }
