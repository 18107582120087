import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useEffect, useState } from 'react'
import { Box, CircularProgress, Container, Link, Typography } from '@mui/material'
import moment from 'moment'

import { Summary, SummaryResponse, Article } from '@types'
import { PublishersApi } from '@api'

function SummaryList() {
  const [summary, setSummary] = useState<Summary[]>([])

  const getPosts = async () => {
    PublishersApi.getSummary()
      .then((response: SummaryResponse) => {
        setSummary(
          response.summary.sort((a: Summary, b: Summary) => {
            const firstArticleA =
              a.articles.length > 0 ? new Date(a.articles[0].published_at).getTime() : 0
            const firstArticleB =
              b.articles.length > 0 ? new Date(b.articles[0].published_at).getTime() : 0
            return firstArticleA - firstArticleB
          }),
        )
      })
      .catch((e: Error) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getPosts()
    const interval = setInterval(() => {
      getPosts()
    }, 600000)
    return () => clearInterval(interval)
  }, [])

  const getImage = (article: Article | undefined) => {
    return (
      <TableCell align="left">
        <img src={`${article?.image_url}`} alt={`${article?.title}`} height="100" width="100" />
      </TableCell>
    )
  }

  const getRowStyle = (d: Date | undefined) => {
    const today = new Date()
    let backgroundCell = '#ffe57c'
    if (today.toDateString() === d?.toDateString()) {
      backgroundCell = ''
    }

    return backgroundCell
  }

  const getSummaryRow = (summary: Summary) => {
    const article = summary.articles.length > 0 ? summary.articles[0] : undefined
    const { publisher } = summary
    return (
      <TableRow
        key={summary.publisher.id}
        sx={{ backgroundColor: getRowStyle(article ? new Date(article.published_at) : undefined) }}
      >
        <TableCell align="left">
          {article
            ? moment(article.published_at).utcOffset('-0300').format('DD-MM-YYYY HH:mm')
            : ''}
        </TableCell>
        <TableCell align="left">{publisher.name}</TableCell>
        {getImage(article)}
        <TableCell align="left">
          <Link href={`${article?.url}`} color="inherit" target="_blank">
            Link
          </Link>
        </TableCell>
        <TableCell align="left">{summary.publisher.id}</TableCell>
      </TableRow>
    )
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4">Status</Typography>
      <br />
      {summary ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Fecha (argentina)</TableCell>
                <TableCell align="left">Medio</TableCell>
                <TableCell align="left">Imagen</TableCell>
                <TableCell align="left">URL</TableCell>
                <TableCell align="left">Publisher ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{summary.map(item => getSummaryRow(item))}</TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  )
}

export { SummaryList }
