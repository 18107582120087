import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useEffect, useState } from 'react'
import { Link, Box, CircularProgress, Container, Typography } from '@mui/material'

import { PublishersResponse, Publisher } from '@types'
import { PublishersApi } from '@api'
import { SearchBar } from '@components'

function PublishersList() {
  const [error, setError] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [query, setQuery] = useState('')
  const [publishers, setPublishers] = useState<Publisher[]>([])

  const fetchPublishers = async () => {
    PublishersApi.getPublishers()
      .then((response: PublishersResponse) => {
        setPublishers(response.publishers)
        setIsLoaded(true)
      })
      .catch((e: Error) => {
        console.log(e)
        setError(e.message)
      })
  }

  const search = (items: Publisher[]) => {
    // eslint-disable-next-line array-callback-return, consistent-return
    return items.filter(item => {
      if (item.name.toLowerCase().includes(query.toLowerCase())) {
        return item
      }
    })
  }

  useEffect(() => {
    fetchPublishers()
  }, [])

  const getImage = (url: string) => {
    if (url) {
      return (
        <TableCell align="center">
          <img src={`${url}`} alt="not found" />
        </TableCell>
      )
    }
    return <TableCell align="left" />
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" sx={{ mb: 2 }}>
        Publishers
      </Typography>

      <SearchBar setSearchQuery={setQuery} />
      {isLoaded ? (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell component="th" width="20%" align="left">
                  ID
                </TableCell>
                <TableCell component="th" width="20%" align="left">
                  Name
                </TableCell>
                <TableCell component="th" width="20%" align="left">
                  URL
                </TableCell>
                <TableCell component="th" width="20%" align="center">
                  Image
                </TableCell>
                <TableCell component="th" width="20%" align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {publishers.length > 0 &&
                search(publishers).map(row => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">
                      <Link href={`https://${row.domain}`} color="inherit" target="_blank">
                        {row.domain}
                      </Link>
                    </TableCell>
                    {getImage(row.image_url)}

                    <TableCell align="center">
                      <Link href={`/publishers/${row.id}/edit`} color="inherit" target="_blank">
                        Edit
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
          {error}
        </Box>
      )}
    </Container>
  )
}

export { PublishersList }
