import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { useUser } from '@contexts'

const PrivateRoute: React.FC<RouteProps> = ({ ...routeProps }: RouteProps) => {
  const { state: user } = useUser()
  return !user ? <Redirect to="/login" /> : <Route {...routeProps} />
}

export { PrivateRoute }
