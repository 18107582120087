import { useCallback, useEffect, useState } from 'react'

import { ArticlesResponse, Article, PaginationParams } from '@types'
import { ArticlesApi } from '@api'

import { ArticlesTable } from '../ArticlesTable'

const AuthorArticlesTable = ({ authorId }) => {
  const [error, setError] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [articles, setArticles] = useState<Article[]>([])
  const [pagination, updatePagination] = useState<PaginationParams>({ page: 0, rowsPerPage: 25 })

  const fetchArticles = useCallback(async () => {
    return ArticlesApi.getArticles({ authorId }, pagination)
      .then((response: ArticlesResponse) => {
        setArticles(response.articles)
        setIsLoaded(true)
      })
      .catch((e: Error) => {
        console.log(e)
        setError(e.message)
      })
  }, [pagination, authorId])

  useEffect(() => {
    fetchArticles()
  }, [fetchArticles])

  return (
    <ArticlesTable
      articles={articles}
      pagination={pagination}
      updatePagination={updatePagination}
      error={error}
      isLoaded={isLoaded}
    />
  )
}

export { AuthorArticlesTable }
