import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import { InputAdornment } from '@mui/material'

const SearchBar = ({ setSearchQuery }) => (
  <TextField
    fullWidth
    id="search-bar"
    className="text"
    onChange={e => {
      setSearchQuery(e.target.value)
    }}
    label=""
    variant="outlined"
    placeholder=""
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />
)

export { SearchBar }
