import React, { useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Link, Box, CircularProgress, TableFooter, TablePagination } from '@mui/material'

import { TablePaginationActions } from '../TablePaginationActions'

const ArticlesTable = ({ pagination, isLoaded, articles, error, updatePagination }) => {
  const getImage = (url: string) => {
    if (url) {
      return <img src={`${url}`} alt="not found" />
    }
    return null
  }
  const handleChangePage = useCallback(
    (event, page) => {
      updatePagination({ ...pagination, page })
    },
    [pagination, updatePagination],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      updatePagination({ page: 0, rowsPerPage: parseInt(event.target.value, 10) })
    },
    [updatePagination],
  )

  return (
    <>
      {isLoaded ? (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell component="th" width="10%" align="left">
                  ID
                </TableCell>
                <TableCell component="th" width="25%" align="left">
                  Title
                </TableCell>
                <TableCell component="th" width="25%" align="left">
                  Authors
                </TableCell>
                <TableCell component="th" width="25%" align="left">
                  URL
                </TableCell>
                <TableCell component="th" width="20%" align="center">
                  Image
                </TableCell>
                <TableCell component="th" width="20%" align="center">
                  Published At
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {articles.length > 0 &&
                articles.map(row => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.title}</TableCell>
                    <TableCell align="left">
                      {row.authors.map(a => (
                        <Link href={`/authors/${a.id}`} color="inherit" target="_blank">
                          {a.name}
                        </Link>
                      ))}
                    </TableCell>
                    <TableCell align="left">
                      <Link href={`${row.url}`} color="inherit" target="_blank">
                        {row.url}
                      </Link>
                    </TableCell>
                    <TableCell align="center"> {getImage(row.image_url)}</TableCell>
                    <TableCell align="left">{row.published_at?.toString()}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  colSpan={6}
                  count={-1}
                  rowsPerPage={pagination.rowsPerPage}
                  page={pagination.page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
          {error}
        </Box>
      )}
    </>
  )
}

export { ArticlesTable }
