import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  Avatar,
  Card,
  CardHeader,
  Container,
  Typography,
  CardContent,
  IconButton,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

import { Author } from '@types'

import { AuthorsApi } from '../../api'
import { AuthorArticlesTable } from '../../components'

const AuthorView = () => {
  const { id } = useParams<{ id: string }>()
  const [author, setAuthor] = useState<Author | undefined>()

  const fetchAuthor = async (authorId: string) => {
    const response = await AuthorsApi.getAuthor(authorId)
    setAuthor(response.author)
  }

  useEffect(() => {
    fetchAuthor(id)
  }, [id])

  return (
    <>
      <Container maxWidth="sm">
        <Card>
          <CardHeader
            avatar={
              <Avatar>
                {author?.image_url ? (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img src={author?.image_url} height={40} width={40} />
                ) : (
                  author?.name.charAt(0)
                )}
              </Avatar>
            }
            title={author?.name}
            subheader={author?.status}
            action={
              <IconButton aria-label="settings" href={`/authors/${id}/edit`}>
                <EditIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Typography>Description</Typography>
            <Typography variant="body2" color="text.secondary">
              {author?.description}
            </Typography>
            <Typography>Created at</Typography>
            <Typography variant="body2" color="text.secondary">
              {author?.created_at?.toString()}
            </Typography>
          </CardContent>
        </Card>
      </Container>
      <Container style={{ marginTop: 32 }}>
        <Typography variant="h4">Articles</Typography>
        <AuthorArticlesTable authorId={id} />
      </Container>
    </>
  )
}

export { AuthorView }
