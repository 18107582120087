import { AuthorResponse, AuthorsResponse, PaginationParams, AuthorFilters, Author } from '@types'

import { api } from './axios'

const getAuthors = async (filters: AuthorFilters, paginationParams: PaginationParams) => {
  const u = new URLSearchParams({
    ...filters,
    page: `${paginationParams.page + 1}`,
    rowsPerPage: `${paginationParams.rowsPerPage}`,
  }).toString()
  const response = await api.get<AuthorsResponse>(`/admin/authors?${u}`)
  return response.data
}

const getAuthor = async (id: string) => {
  const response = await api.get<AuthorResponse>(`/admin/authors/${id}`)
  return response.data
}

const updateAuthor = async (author: Author, image: File | null) => {
  const { id, name, description = '', status } = author
  const formData = new FormData()
  console.log(image)
  if (image) {
    formData.append('file', image)
  }
  formData.append(
    'entity',
    JSON.stringify({
      name,
      description,
      status,
    }),
  )
  const response = await api.put<AuthorResponse>(`/admin/authors/${id}`, formData)
  return response.data
}

export const AuthorsApi = { getAuthors, getAuthor, updateAuthor }
