import { useHistory, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import {
  Container,
  Grid,
  Button,
  TextField,
  Box,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material'

import { EditUserDto, UserRoles } from '@types'

import { UsersApi } from '../../api'

const UserEdit = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const [user, setUser] = useState<EditUserDto | undefined>()
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const fetchUser = async (userId: string) => {
    const response = await UsersApi.getUser(userId)
    setUser({ ...response.user })
  }

  useEffect(() => {
    if (id) {
      fetchUser(id)
    }
  }, [id])

  const onSubmit = useCallback(
    async (data: EditUserDto | undefined) => {
      setLoading(true)
      if (data) {
        try {
          if (id) {
            await UsersApi.updateUser(data)
          } else {
            await UsersApi.createUser(data)
          }
          setError('')
        } catch (e) {
          setError('Algo salio mal :(')
        }
      }
      setLoading(false)
      if (!id) {
        history.replace('/users')
      }
    },
    [id, history],
  )
  return (
    <>
      <Container maxWidth="sm">
        <Typography variant="h4">{id ? 'Edit User' : 'Create User'}</Typography>
        <Box
          component="form"
          noValidate
          onSubmit={e => {
            e.preventDefault()
            onSubmit(user)
          }}
          sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="email"
                name="email"
                required
                fullWidth
                id="email"
                label="Email"
                autoFocus
                value={user?.email ?? ''}
                onChange={e => setUser({ ...user, email: e.target.value } as EditUserDto)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="password"
                name="password"
                required
                fullWidth
                id="password"
                label="Password"
                placeholder="********"
                autoFocus
                value={user?.password}
                onChange={e => setUser({ ...user, password: e.target.value } as EditUserDto)}
              />
            </Grid>
            {(user || !id) && (
              <Grid item xs={12}>
                <InputLabel id="status-select-label">Role</InputLabel>

                <Select
                  labelId="status-select-label"
                  value={user?.role}
                  label="Status"
                  onChange={e => setUser({ ...user, role: e.target.value } as EditUserDto)}
                >
                  {Object.keys(UserRoles).map((as: string) => (
                    <MenuItem value={UserRoles[as]}>{as}</MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
          </Grid>
          {!isLoading ? (
            <>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Save
              </Button>
              {error}
            </>
          ) : (
            <CircularProgress />
          )}
        </Box>
      </Container>
    </>
  )
}

export { UserEdit }
