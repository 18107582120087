export type User = {
  id: string
  email: string
  role: string
  created_at: Date
}

export interface Account {
  id: number
  alias: string
  code: string
  createdAt: string
  currency: string
  bankName: string
}

export interface ErrorResponse {
  type: string
  message: string
}

export type Publisher = {
  id: string
  domain: string
  name: string
  image_url: string
}

export enum AuthorStatus {
  PENDING = 'PENDING',
  HIDE = 'HIDE',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum UserRoles {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
}

export type Author = {
  id: string
  name: string
  status?: string
  image_url?: string
  description?: string
  created_at?: Date
}

export type Article = {
  id: string
  authors: Author[]
  title: string
  url: string
  published_at: Date
  image_url: string
}

export interface PublishersResponse {
  publishers: Publisher[]
}

export interface PublisherResponse {
  publisher: Publisher
}

export interface SummaryResponse {
  summary: Summary[]
}

export interface ArticlesResponse {
  articles: Article[]
}

export interface Summary {
  publisher: Publisher
  articles: Article[]
}

export interface Country {
  id: number
  createdAt: Date
  name: string
  code: string
  availableToTrade: boolean
  currency: string
}

export interface AuthResponse {
  user: User
  token: string
  refreshToken: string
}

export interface PaginatedResponse<T> {
  data: T[]
  total: number
  page: number
  lastPage: number
  next?: string
  previous?: string
}

export enum TableStatusEnum {
  READY_TO_REGISTER = 'READY_TO_REGISTER',
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  LOST_CONNECTION = 'LOST_CONNECTION',
}

export interface GameTableFilters {
  macAddress?: string
  localId?: string
  status?: TableStatusEnum
}

export interface GameTable {
  id: number
  localId: string | null
  macAddress: string
  createdAt: Date
  updatedAt: Date
  lastConnection: Date
  status: TableStatusEnum
  price: number
}

export interface GameTableParams {
  macAddress?: string
  localId?: string
  status?: TableStatusEnum
  page?: number
  pageLength?: number
}

export interface UpdateGameTableRequest {
  id: number
  localId: string
  price: number
}

export interface AuthorsResponse {
  authors: Author[]
}

export interface AuthorResponse {
  author: Author
}

export interface AuthorFilters {
  name?: string
  status?: string
}

export interface ArticlesFilters {
  title?: string
  authorId?: string
  publisherId?: string
}

export interface PaginationParams {
  page: number
  rowsPerPage: number
}

export interface CountResponse {
  count: number
}

export interface UsersResponse {
  users: User[]
}

export interface UserResponse {
  user: User
}

export interface EditUserDto {
  id?: string
  role: string
  password?: string
  email: string
}
