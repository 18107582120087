import { useCallback, useEffect, useState } from 'react'
import { Container, Typography } from '@mui/material'

import { ArticlesResponse, Article, PaginationParams } from '@types'
import { ArticlesApi } from '@api'
import { SearchBar } from '@components'

import { useDebounce } from '../../hooks'
import { ArticlesTable } from '../../components/ArticlesTable'

const ArticlesList = () => {
  const [error, setError] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [query, setQuery] = useState('')
  const [count, setCount] = useState<number>(0)
  const debouncedQuery = useDebounce(query, 200)
  const [articles, setArticles] = useState<Article[]>([])
  const [pagination, updatePagination] = useState<PaginationParams>({ page: 0, rowsPerPage: 25 })

  const fetchArticles = useCallback(async () => {
    return ArticlesApi.getArticles({ title: debouncedQuery }, pagination)
      .then((response: ArticlesResponse) => {
        setArticles(response.articles)
        setIsLoaded(true)
      })
      .catch((e: Error) => {
        console.log(e)
        setError(e.message)
      })
  }, [debouncedQuery, pagination])

  const fetchArticlesCount = useCallback(async () => {
    const { count: articlesCount } = await ArticlesApi.getCount()
    setCount(articlesCount)
  }, [])

  useEffect(() => {
    fetchArticles()
  }, [fetchArticles])

  useEffect(() => {
    fetchArticlesCount()
  }, [fetchArticlesCount])

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" sx={{ mb: 2 }}>
        Articles [{count}]
      </Typography>
      <SearchBar
        setSearchQuery={(q: string) => {
          setQuery(q)
          updatePagination({ ...pagination, page: 0 })
        }}
      />
      <ArticlesTable
        articles={articles}
        pagination={pagination}
        updatePagination={updatePagination}
        error={error}
        isLoaded={isLoaded}
      />
    </Container>
  )
}

export { ArticlesList }
