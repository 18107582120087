import { useMutation } from 'react-query'
import { AxiosError } from 'axios'

import { useLoginResponseManager } from '@hooks'
import { AuthApi, setAuthHeader } from '@api'
import { LOCAL_STORAGE_KEYS } from '@constants'
import { ErrorResponse } from '@types'

interface UseLoginProps {
  onError?: (error: ErrorResponse) => void
}

const useLogin = (props?: UseLoginProps) => {
  const { saveUserFromLoginResponse } = useLoginResponseManager()

  const { mutate, isLoading } = useMutation(AuthApi.login, {
    onSuccess: ({ token, refreshToken, user }) => {
      setAuthHeader(token)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken)

      saveUserFromLoginResponse(user)
    },
    onError: (error: AxiosError) => {
      const errorResponse = error.response?.data as unknown as ErrorResponse
      if (props?.onError) {
        props.onError(errorResponse)
      }
    },
  })

  return { login: mutate, isLoading }
}

export { useLogin }
