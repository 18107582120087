import { SummaryResponse, PublishersResponse, Publisher, PublisherResponse } from '@types'

import { api } from './axios'

const getSummary = async () => {
  const response = await api.get<SummaryResponse>('/admin/articles/summary')
  return response.data
}

const getPublishers = async () => {
  const response = await api.get<PublishersResponse>('/publishers')
  return response.data
}

const getById = async (id: string) => {
  const response = await api.get<PublisherResponse>(`/admin/publishers/${id}`)
  return response.data
}

const update = async (publisher: Publisher, image: File | null) => {
  const { id, name } = publisher
  const formData = new FormData()
  if (image) {
    formData.append('file', image)
  }
  formData.append(
    'entity',
    JSON.stringify({
      name,
    }),
  )
  const response = await api.put<PublisherResponse>(`/admin/publishers/${id}`, formData)
  return response.data
}

export const PublishersApi = { getSummary, getPublishers, getById, update }
