import { UserResponse, UsersResponse, PaginationParams, EditUserDto } from '@types'

import { api } from './axios'

const getUsers = async (paginationParams: PaginationParams) => {
  const u = new URLSearchParams({
    page: `${paginationParams.page + 1}`,
    rowsPerPage: `${paginationParams.rowsPerPage}`,
  }).toString()
  const response = await api.get<UsersResponse>(`/admin/users?${u}`)
  return response.data
}

const getUser = async (id: string) => {
  const response = await api.get<UserResponse>(`/admin/users/${id}`)
  return response.data
}

const updateUser = async (user: EditUserDto) => {
  const { id, email, role, password } = user
  const response = await api.put<UserResponse>(`/admin/users/${id}`, {
    email,
    role,
    password,
  })
  return response.data
}

const createUser = async (user: EditUserDto) => {
  const { email, role, password } = user
  const response = await api.post<UserResponse>('/admin/users', {
    email,
    role,
    password,
  })
  return response.data
}

export const UsersApi = { getUser, getUsers, createUser, updateUser }
