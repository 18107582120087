import { useParams } from 'react-router-dom'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Container, Grid, Button, TextField, Box, Avatar, CircularProgress } from '@mui/material'

import { Publisher } from '@types'
import { PublishersApi } from '@api'

const PublisherEdit = () => {
  const { id } = useParams<{ id: string }>()
  const [item, setItem] = useState<Publisher | undefined>()
  const [imageToUpload, setImageToUpload] = useState<File | null>(null)
  const [itemImage, setItemImage] = useState<string | undefined>()
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const fetchItem = async (itemId: string) => {
    const { publisher } = await PublishersApi.getById(itemId)
    setItem(publisher)
    setItemImage(publisher.image_url)
  }

  useEffect(() => {
    fetchItem(id)
  }, [id])

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const imageFile = e.target.files[0]
    if (imageFile.size > 3_000_000) {
      setImageToUpload(null)
      setItemImage(undefined)
    } else {
      setImageToUpload(imageFile)
      setItemImage(URL.createObjectURL(imageFile))
    }
  }

  const removeImage = useCallback(() => {
    if (imageToUpload) {
      setImageToUpload(null)
      setItemImage(undefined)
    }
  }, [imageToUpload])

  const onSubmit = useCallback(
    async (data: Publisher | undefined) => {
      setLoading(true)
      if (data) {
        try {
          await PublishersApi.update(data, imageToUpload)
          setError('')
        } catch (e) {
          setError('Algo salio mal :(')
        }
      }
      setLoading(false)
    },
    [imageToUpload],
  )
  return (
    <>
      <Container maxWidth="sm">
        <Box
          component="form"
          noValidate
          onSubmit={e => {
            e.preventDefault()
            onSubmit(item)
          }}
          sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <Avatar src={itemImage} sx={{ width: 100, height: 100, mb: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Button color="primary" variant="contained" component="label" sx={{ height: 48 }}>
                    Choose Image
                    <input
                      hidden
                      accept="image/png, image/gif, image/jpeg"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disabled={!imageToUpload}
                    color="primary"
                    variant="contained"
                    component="label"
                    sx={{ height: 48, ml: 2 }}
                    onClick={() => removeImage()}
                  >
                    Remove Image
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="name"
                name="name"
                required
                fullWidth
                id="name"
                label="Name"
                autoFocus
                value={item?.name ?? ''}
                onChange={e => setItem({ ...item, name: e.target.value } as Publisher)}
              />
            </Grid>
          </Grid>
          {!isLoading ? (
            <>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Save
              </Button>
              {error}
            </>
          ) : (
            <CircularProgress />
          )}
        </Box>
      </Container>
    </>
  )
}

export { PublisherEdit }
