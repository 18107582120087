import { PaginationParams, ArticlesFilters, ArticlesResponse, CountResponse } from '@types'

import { api } from './axios'

const getArticles = async (filters: ArticlesFilters, paginationParams: PaginationParams) => {
  const u = new URLSearchParams({
    ...filters,
    page: `${paginationParams.page + 1}`,
    rowsPerPage: `${paginationParams.rowsPerPage}`,
  }).toString()
  const response = await api.get<ArticlesResponse>(`/admin/articles?${u}`)
  return response.data
}

const getCount = async () => {
  const response = await api.get<CountResponse>('/admin/articles/count')
  return response.data
}

export const ArticlesApi = { getArticles, getCount }
