import { useHistory } from 'react-router-dom'

import { useUser } from '@contexts'
import { User } from '@types'

const useLoginResponseManager = () => {
  const { setState } = useUser()
  const { location, push } = useHistory()
  const saveUserFromLoginResponse = (user: User) => {
    setState(user)
    if (location.pathname === '/login') {
      push('/')
    }
  }

  return { saveUserFromLoginResponse }
}

export { useLoginResponseManager }
