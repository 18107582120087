import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { UserProvider } from '@contexts'
import { GeneralLayout, PrivateRoute } from '@components'

import { Login } from './Login'
import { SummaryList } from './Summary/SummaryList'
import { PublishersList, PublisherEdit } from './Publishers'
import { AuthorsList, AuthorView } from './Authors'
import { ArticlesList } from './Articles/ArticlesList'
import { AuthorEdit } from './Authors/AuthorEdit'
import { UsersList, UserEdit } from './Users'

const queryClient = new QueryClient()
const theme = createTheme()

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <Switch>
              <Route path="/login" component={Login} />
              <PrivateRoute
                exact
                path="/"
                render={() => <GeneralLayout body={<SummaryList />} />}
              />
              <PrivateRoute
                exact
                path="/publishers"
                render={() => <GeneralLayout body={<PublishersList />} />}
              />
              <PrivateRoute
                exact
                path="/publishers/:id/edit"
                render={() => <GeneralLayout body={<PublisherEdit />} />}
              />
              <PrivateRoute
                exact
                path="/authors"
                render={() => <GeneralLayout body={<AuthorsList />} />}
              />
              <PrivateRoute
                exact
                path="/authors/:id"
                render={() => <GeneralLayout body={<AuthorView />} />}
              />
              <PrivateRoute
                exact
                path="/authors/:id/edit"
                render={() => <GeneralLayout body={<AuthorEdit />} />}
              />
              <PrivateRoute
                exact
                path="/articles"
                render={() => <GeneralLayout body={<ArticlesList />} />}
              />
              <PrivateRoute
                exact
                path="/users"
                render={() => <GeneralLayout body={<UsersList />} />}
              />
              <PrivateRoute
                exact
                path="/users/new"
                render={() => <GeneralLayout body={<UserEdit />} />}
              />
              <PrivateRoute
                exact
                path="/users/:id/edit"
                render={() => <GeneralLayout body={<UserEdit />} />}
              />
              <Redirect to="/login" />
            </Switch>
          </UserProvider>
        </QueryClientProvider>
      </Router>
    </ThemeProvider>
  )
}

export { App }
